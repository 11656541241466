import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Space, Typography, Menu } from 'antd';
import { useWindow } from '../reservas/hooks/useWindow';
import { ApiContext } from '../../context/ApiContext';
import { ApiLogin } from '../../api/login';
import { Spinner } from '../ui/Spinner';


const items = [
  {
    key: '1',
    label: 'Logout',
  },
];

export const MenuUser = () => {

  const [ auth, guardarAuth ] = useContext(ApiContext);
  const { username, token } = auth;

  const [showSpineer, setShowSpinner] = useState(false); 

  const navigate = useNavigate();
  const ancho = useWindow();

  const handleMenuClick = async (e) => {

    if (e.key === '1') {

      setShowSpinner(true);
     
        try {

           const respuesta = await ApiLogin.post('Authenticate/logout', {
                username,
                token
          });
              
          const { ok } = respuesta.data;
              
          if(ok){
    
            guardarAuth({
              token: '',
              auth: false,
              agents: null,
              username: '',
              destinations:'',
              origins:'',
              products:'',
              shc:'',
            });

            setShowSpinner(true);

            localStorage.clear();            
            navigate('/');
          } 
          
        } catch (error) {
          console.log(error);    

          guardarAuth({
            token: '',
            auth: false,
            agents: null,
            username: '',
            destinations:'',
            origins:'',
            products:'',
            shc:'',
          });

          localStorage.clear();            
          setShowSpinner(false);
          navigate('/');
        }
      
    }
  };

  return (
    <>
      <Dropdown 
        overlay={<Menu items={items} onClick={handleMenuClick} />} 
        trigger={['click']} // Defino el tipo de activacion
      >
        <div 
          style={{ 
             marginTop: '0.2rem',
             position: 'absolute',
             right: 10
          }}
        >
          <Space direction='vertical'>

            <Typography.Text 
              style={{ 
                fontSize:  '1rem',
                color: '#1e1d58'
              }}
            >
              { username && ancho > 768 ? username : ''} <i class='fa-duotone fa-regular fa-circle-user fa-lg'></i>
               
            </Typography.Text>    
          </Space>

        </div>

      </Dropdown>

      { 
        (showSpineer) && <Spinner />
      }
      
    </>    
  );
};

