import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, Button, Card, Row, Col, Select, Divider, Collapse } from 'antd';
import { formStyle, sectionStyle, cardSectionStyle, cardHeaderStyle, cardBodyStyle, cardSectionHeaderStyle, reducedMarginFormItem } from '../formStyles';
import moment from 'moment';
import './BookingList.css';
import { AcsApi, ApiCatch } from '../../api/AcsApi';

const { Option } = Select;

export const BookingForm = () => {
    const [form] = Form.useForm();
    const [bookings, setBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [pageInfo, setPageInfo] = useState({
        currentPage: 0,
        totalPages: 0,
        size: 10
    });

    const token = localStorage.getItem('token');

    const fetchBookings = async (page = 0, queryParams = {}) => {
        try {
            const params = new URLSearchParams({
                page,
                size: 10,
                ...queryParams
            });
            const url = `https://api-portal.aeronexcargo.com/booking/v2/bookings?${params.toString()}`;
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'sessionid': token,
                }
            });
            const data = await response.json();
            setBookings(data._embedded.bookings);
            setPageInfo({
                currentPage: data.page.number,
                totalPages: data.page.totalPages,
                size: data.page.size
            });
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    //   const fetchBookings2 = async (page = 0, queryParams = {}) => {

    //         setShowSpinner(true);
    //         await AcsApi.get('/booking/v2/bookings', output, {
    //             headers: {
    //                 sessionid: token,
    //             }
    //         })
    //             .then(response => {
    //                 console.log('Success:', response.data);
    //                 Alert('The Booking is confirmed',
    //                     'AWB ' + response.data.airWaybill.prefix + '-' + response.data.airWaybill.serial +
    //                     '\nfor ' + response.data.agent.accountNumber,
    //                     'success');

    //                 localStorage.setItem('send', 'ok');
    //                 setShowSpinner(false);
    //                 navigate('/form');
    //             })
    //             .catch(error => {
    //                 setShowSpinner(false);
    //                 ApiCatch(error);
    //             });
    //     }

    useEffect(() => {
        // Set initial form values for last 30 days
        const thirtyDaysAgo = moment().subtract(30, 'days');
        form.setFieldsValue({
            departureFrom: thirtyDaysAgo
        });

        // Fetch bookings with initial parameters
        const initialParams = {
            departureFrom: thirtyDaysAgo.utc().startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]')
        };
        fetchBookings(0, initialParams);
    }, [form]);

    const onSearch = (values, page = 0) => {
        const queryParams = {};

        if (values.accountNumbers?.length) {
            queryParams.accountNumbers = values.accountNumbers.join(',');
        }
        if (values.origin) {
            queryParams.origin = values.origin.toUpperCase();
        }
        if (values.destination) {
            queryParams.destination = values.destination.toUpperCase();
        }
        if (values.departureFrom) {
            // Format to UTC midnight
            queryParams.departureFrom = values.departureFrom.startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');//.utc()
        }
        if (values.departureUntil) {
            // Format to UTC end of day
            queryParams.departureUntil = values.departureUntil.endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');//.utc()
        }

        fetchBookings(page, queryParams);
    };

    const handleRowClick = (booking) => {
        setSelectedBooking(booking);
    };

    const handlePageChange = (page) => {
        const values = form.getFieldsValue();
        onSearch(values, page);
        //fetchBookings(page, values);
    };

    const handleEdit = () => {
        if (selectedBooking) {
            window.open(`/edit-booking/${selectedBooking.businessId}`, '_blank');
        }
    };

    const handleCancel = async () => {
        if (selectedBooking && window.confirm('Are you sure you want to cancel this booking?')) {
            try {
                await fetch(selectedBooking._links.cancel.href, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }
                });
                alert('Booking cancellation requested');
                fetchBookings(pageInfo.currentPage);
                setSelectedBooking(null);
            } catch (error) {
                console.error('Error cancelling booking:', error);
                alert('Failed to cancel booking');
            }
        }
    };

    return (
        <div
            className="booking-form"
            style={formStyle}
        >
            <Card title="Bookings Overview" style={sectionStyle} headStyle={cardHeaderStyle} bodyStyle={cardBodyStyle}>

                {/* Search Form Section */}
                <div className="search-section" style={cardSectionStyle}>
                    <Form
                        form={form}
                        layout="inline"
                        onFinish={onSearch}
                        style={{ marginBottom: 20 }}
                    >
                        <Row gutter={12}>
                            <Col xs={24} md={24} lg={12}>
                                <Form.Item name="accountNumbers" label="Account Numbers">
                                    <Select mode="tags" style={{ width: 200 }} placeholder="Enter account numbers" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item name="origin" label="Origin">
                                    <Input style={{ width: 100 }} placeholder="e.g. MIA" />
                                </Form.Item>
                                <Form.Item name="destination" label="Destination">
                                    <Input style={{ width: 100 }} placeholder="e.g. JFK" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item name="departureFrom" label="Departure From">
                                    <DatePicker format="YYYY-MM-DD" />
                                </Form.Item>
                                <Form.Item name="departureUntil" label="Departure Until">
                                    <DatePicker format="YYYY-MM-DD" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Search
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={() => {
                                    form.resetFields();
                                    fetchBookings(0);
                                }}>
                                    Clear
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </div>

                {/* Bookings Table Section */}
                <div className="table-section" style={cardSectionStyle}>
                    <table>
                        <thead>
                            <tr>
                                <th>AWB</th>
                                <th>Agent</th>
                                <th>Route</th>
                                <th>Pieces</th>
                                <th>Weight</th>
                                <th>Product</th>
                                <th>Issued On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookings.map((booking) => (
                                <tr
                                    key={booking.businessId}
                                    onClick={() => handleRowClick(booking)}
                                    className={selectedBooking?.businessId === booking.businessId ? 'selected' : ''}
                                >
                                    <td>{`${booking.airWaybillIdentifier.airlinePrefix}-${booking.airWaybillIdentifier.serial}`}</td>
                                    <td>{booking.agent.accountNumber}</td>
                                    <td>{`${booking.origin.code}-${booking.destination.code}`}</td>
                                    <td>{booking.totalPieces}</td>
                                    <td>{`${booking.totalWeight.amount} ${booking.totalWeight.unit}`}</td>
                                    <td>{booking.product?.code || '-'}</td>
                                    <td>{booking.airwaybillsIssuedOn || '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="pagination">
                        <button
                            onClick={() => handlePageChange(0)}
                            disabled={pageInfo.currentPage === 0}
                        >
                            First
                        </button>
                        <button
                            onClick={() => handlePageChange(pageInfo.currentPage - 1)}
                            disabled={pageInfo.currentPage === 0}
                        >
                            Previous
                        </button>
                        <span>Page {pageInfo.currentPage + 1} of {pageInfo.totalPages}</span>
                        <button
                            onClick={() => handlePageChange(pageInfo.currentPage + 1)}
                            disabled={pageInfo.currentPage === pageInfo.totalPages - 1}
                        >
                            Next
                        </button>
                        <button
                            onClick={() => handlePageChange(pageInfo.totalPages - 1)}
                            disabled={pageInfo.currentPage === pageInfo.totalPages - 1}
                        >
                            Last
                        </button>
                    </div>
                </div>

                {/* Booking Details Section */}
                {selectedBooking && (
                    <>
                        <Divider
                            style={cardSectionHeaderStyle}
                            orientation="left"
                            orientationMargin={40}
                        >
                            Booking Details
                        </Divider>
                        <div className="details-section" style={cardSectionStyle}>
                            <div className="details-grid">
                                <div>
                                    <label>AWB:</label>
                                    <input
                                        value={`${selectedBooking.airWaybillIdentifier.airlinePrefix}-${selectedBooking.airWaybillIdentifier.serial}`}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Agent:</label>
                                    <input value={selectedBooking.agent.name} readOnly />
                                </div>
                                <div>
                                    <label>Origin:</label>
                                    <input value={`${selectedBooking.origin.code} - ${selectedBooking.origin.name}`} readOnly />
                                </div>
                                <div>
                                    <label>Destination:</label>
                                    <input value={`${selectedBooking.destination.code} - ${selectedBooking.destination.name}`} readOnly />
                                </div>
                                <div>
                                    <label>Status:</label>
                                    <input value={selectedBooking.bookingStatus || '-'} readOnly />
                                </div>
                                <div>
                                    <label>Nature of Goods:</label>
                                    <input value={selectedBooking.natureOfGoods} readOnly />
                                </div>
                                <div>
                                    <label>Total Pieces:</label>
                                    <input value={selectedBooking.totalPieces} readOnly />
                                </div>
                                <div>
                                    <label>Total Weight:</label>
                                    <input value={`${selectedBooking.totalWeight.amount} ${selectedBooking.totalWeight.unit}`} readOnly />
                                </div>
                                <div>
                                    <label>Carrier:</label>
                                    <input value={selectedBooking.carrier.code} readOnly />
                                </div>
                                <div>
                                    <label>Special Handling:</label>
                                    <input
                                        value={selectedBooking.specialHandling.map(sh => sh.code).join(', ') || '-'}
                                        readOnly
                                    />
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="action-buttons">
                                <button onClick={handleEdit}>Edit</button>
                                {selectedBooking._links.cancel && (
                                    <button onClick={handleCancel}>Cancel</button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Card>
        </div>
    );
};

export default BookingForm;