import React, { useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { RightSquareTwoTone, HomeOutlined, MenuOutlined, SettingOutlined, 
          FormOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Layout, Menu, Button,  } from 'antd';
import { TrackingMain, Home, Booking, NewBooking, FormTesting, AnnounsementsMain } from './index';
import { ListBookings } from '../ListBookings/ListBookingsMain';
import { UserSettingsMain } from '../usersettings/UserSettingsMain';
import { ReservaProvider } from './context/reservaContext';
import { MenuUser } from '../user/MenuUser';
import { useWindow } from './hooks/useWindow';
import { DimensionsMasterForm } from './FormDimensionsTest2'; // Import the BoxDimensionsForm component
import { BookingForm } from './BookingList'; // Import the BoxDimensionsForm component

const { Header, Sider, Content } = Layout;

export const NavegacionReservas = () => {

  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const ancho = useWindow();

  const toggleCollapsed = ({key}) => {
    if(window.innerWidth > 768 && !collapsed)  return;
    navigate(key)
    setCollapsed(!collapsed);
  };

  return (

    <Layout style={{ minHeight: '100vh'}}>

      <Sider 
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}         
        style={{
          background: 'linear-gradient(to bottom, #848484, #464646, #060606)',
          position: ancho < 768 ? 'fixed' : undefined, 
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 9999,
        }}
      >
        {
          (!collapsed && window.innerWidth < 768) && 
            <div
              className='overlay'          
              onClick={toggleCollapsed}
            />
        }

        <div style={{margin: '1.8rem'}}>
          <img  width='130' height='auto' src={require('../ui/img/aeronexcargologo.png')} alt="Aeronex" />
        </div> 
      
          <Menu
            theme="dark"
            style={{
              background: 'none', // Remove background color
              // Directly targeting the menu items to remove the underline
              '& .ant-menu-item-selected': {
                textDecoration: 'none', // Remove underline for selected item
              },
              '& .ant-menu-item': {
                textDecoration: 'none', // Remove underline for all items
              },
            }}
            mode="vertical"
            defaultSelectedKeys={['1']}
            items={[
              // {
              //   label: <Link to="/form" onClick={toggleCollapsed}>Home</Link>,
              //   key: '1',
              //   icon: <HomeOutlined />,
              // },
              {              
                label: <Link to="/form/bookings" onClick={toggleCollapsed}>Home</Link>,
                key: '1',
                icon: <EditOutlined />,
              },
              {
                label: <Link to="/form/new" onClick={toggleCollapsed}>New Booking</Link>,
                key: '2',
                icon: <FormOutlined />,
              },
              // {              
              //   label: <Link to="/form/bookings" onClick={toggleCollapsed}>Bookings</Link>,
              //   key: '3',
              //   icon: <EditOutlined />,
              // },
              {
                label: <Link to="/form/tracking" onClick={toggleCollapsed}>Tracking</Link>,
                key: '4',
                icon: <SearchOutlined />,
              },
              {
                label: <Link to="/form/usersettings" onClick={toggleCollapsed}>User Settings</Link>,
                key: '5',
                icon: <SettingOutlined />,
              },
              {
                label: <Link to="/form/announcements" onClick={toggleCollapsed}>Announcements</Link>,
                key: '6',
                icon: <RightSquareTwoTone />,
              },
               {
                 label: <Link to="/form/dimensiontest2" onClick={toggleCollapsed}>Dimension Test Form</Link>,
                 key: '7',
                 icon: <RightSquareTwoTone />,
               },
               {
                label: <Link to="/form/bookinglist" onClick={toggleCollapsed}>Dimension Test Form</Link>,
                key: '8',
                icon: <RightSquareTwoTone />,
              },
              // {
              //   label: <Link to="/form/test" onClick={toggleCollapsed}>Test Form</Link>,
              //   key: '7',
              //   icon: <RightSquareTwoTone />,
              // },
            ]}

          /> 

          <div style={{margin: '1.8rem', marginTop: '20rem'}}>
          {/* <div style={{margin: '1.8rem', marginTop: '100%'}}> */}

            <p style={{color: 'white', fontSize: 12.5}}>CARGO REPRESENTATIVE</p>
              <img 
                width='95%' 
                height='auto'
                src={require('../ui/img/JBCargoLogo.png')} 
                alt="Logo" 
              />
          </div>       
          
      </Sider>

     <Layout >

        <Header
          style={{
            padding: 0,
            height: '4rem',
            background:'#f8f8f8',
          }}
        >

          <div style={{display: 'flex', justifyContent: 'space-between',}}>
            
            {
                (collapsed) && (

                    <Button type='text' onClick={toggleCollapsed} style={{ marginTop: 15 }}>
                      <MenuOutlined />
                    </Button>            
                ) 
                
            }
              <MenuUser />

          </div>

        </Header>

            <Content
              className='content'
              style={{
                  margin: '0rem 0rem',
                  padding: 0,
                  minHeight: 400,   
                }}
            >

              <ReservaProvider>
                <Routes>
                  <Route path='/' Component={Home}/>             
                  <Route path='/new' Component={NewBooking}/>             
                  <Route path='/bookings' Component={ListBookings}/>             
                  <Route path='/booking' Component={Booking}/>             
                  <Route path='/tracking' Component={TrackingMain}/>       
                  <Route path='/usersettings' Component={UserSettingsMain}/>         
                  <Route path='/test' Component={FormTesting}/>   
                  <Route path='/announcements' Component={AnnounsementsMain}/>
                  <Route path='/dimensiontest2' Component={DimensionsMasterForm}/>
                  <Route path='/bookinglist' Component={BookingForm}/>
                </Routes>              
              </ReservaProvider>
              
            </Content>
      </Layout>
    </Layout>
  );
};
