import React, { useState } from 'react';
import { cardStyle, formStyle, cardSectionStyle, cardHeaderStyle, cardBodyStyle, cardSectionHeaderStyle, reducedMarginFormItem } from '../formStyles';
import { Form, InputNumber, Select, Button, Card, Space, Col, Row, Typography, Collapse } from 'antd';
import BoxDimensionsForm from './BoxDimensionsForm';

const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;

export const DimensionsMasterForm = () => {
  const [form] = Form.useForm();
  const [unitSystem, setUnitSystem] = useState('imperial');
  const [dataSource, setDataSource] = useState([]);
  const [boxUnitSystem, setBoxUnitSystem] = useState('imperial'); // Track BoxDimensionsForm's unit system

  const convertToImperial = (value, field) => {
    if (field === 'weight') return value * 2.20462;
    if (field === 'density') return value * 0.062428;
    return value * 0.393701; // length
  };

  const convertToMetric = (value, field) => {
    if (field === 'weight') return value * 0.453592;
    if (field === 'density') return value * 16.0185;
    return value * 2.54; // length
  };

  const convertDimensionsToUnitSystem = (dimensions, fromUnitSystem, toUnitSystem) => {
    if (fromUnitSystem === toUnitSystem) return dimensions;

    return dimensions.map(record => {
      if (fromUnitSystem === 'metric' && toUnitSystem === 'imperial') {
        return {
          ...record,
          height: Math.round(convertToImperial(record.height, 'height')),
          width: Math.round(convertToImperial(record.width, 'width')),
          length: Math.round(convertToImperial(record.length, 'length')),
          weight: Math.round(convertToImperial(record.weight, 'weight')),
        };
      } else if (fromUnitSystem === 'imperial' && toUnitSystem === 'metric') {
        return {
          ...record,
          height: Math.round(convertToMetric(record.height, 'height')),
          width: Math.round(convertToMetric(record.width, 'width')),
          length: Math.round(convertToMetric(record.length, 'length')),
          weight: Math.round(convertToMetric(record.weight, 'weight')),
        };
      }
      return record;
    });
  };

  const onFinish = (values) => {
    // Convert dimensions to MasterForm's unit system if different
    const convertedDimensions = convertDimensionsToUnitSystem(dataSource, boxUnitSystem, unitSystem);

    const submissionData = {
      ...values,
      dimensions: convertedDimensions,
    };
    console.log('Master Form Submission:', submissionData);
  };

  return (
    <div style={formStyle}>
      <Card title="Dimensions Master Form" style={cardStyle} headStyle={cardHeaderStyle} bodyStyle={cardBodyStyle}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            unitSystem: 'imperial',
            requiredTotalPieces: 0,
            requiredTotalWeight: 0,
            maxHeight: 60,
            maxWidth: 60,
            maxLength: 60,
            maxWeightPerPiece: 120,
            minDensity: 0.02,
            maxDensity: 6,
          }}
        >
          <Space style={cardSectionStyle}>
            <Form.Item
              name="unitSystem"
              label="Unit System"
              rules={[{ required: true, message: 'Please select a unit system' }]}
            >
              <Select onChange={(value) => setUnitSystem(value)}>
                <Option value="imperial">Imperial (in/lb)</Option>
                <Option value="metric">Metric (cm/kg)</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="requiredTotalPieces"
              label="Required Total Pieces"
              rules={[{ required: true, message: 'Please enter total pieces' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="requiredTotalWeight"
              label={`Required Total Weight (${unitSystem === 'imperial' ? 'lb' : 'kg'})`}
              rules={[{ required: true, message: 'Please enter total weight' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="maxHeight"
              label={`Max Height (${unitSystem === 'imperial' ? 'in' : 'cm'})`}
              rules={[{ required: true, message: 'Please enter max height' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="maxWidth"
              label={`Max Width (${unitSystem === 'imperial' ? 'in' : 'cm'})`}
              rules={[{ required: true, message: 'Please enter max width' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="maxLength"
              label={`Max Length (${unitSystem === 'imperial' ? 'in' : 'cm'})`}
              rules={[{ required: true, message: 'Please enter max length' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="maxWeightPerPiece"
              label={`Max Weight Per Piece (${unitSystem === 'imperial' ? 'lb' : 'kg'})`}
              rules={[{ required: true, message: 'Please enter max weight per piece' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="minDensity"
              label={`Min Density (${unitSystem === 'imperial' ? 'lb/ft³' : 'kg/m³'})`}
              rules={[{ required: true, message: 'Please enter min density' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="maxDensity"
              label={`Max Density (${unitSystem === 'imperial' ? 'lb/ft³' : 'kg/m³'})`}
              rules={[{ required: true, message: 'Please enter max density' }]}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Space>
          <Collapse defaultActiveKey={['1']} style={{ marginTop: 24 }}>
            <Panel
              header={<span >Dimensions</span>}//style={{ fontWeight: 'bold' }}
              key="1"
              style={cardSectionHeaderStyle} // Light blue background
            >
              <BoxDimensionsForm
                requiredTotalPieces={form.getFieldValue('requiredTotalPieces')}
                requiredTotalWeight={form.getFieldValue('requiredTotalWeight')}
                maxHeight={form.getFieldValue('maxHeight')}
                maxWidth={form.getFieldValue('maxWidth')}
                maxLength={form.getFieldValue('maxLength')}
                maxWeightPerPiece={form.getFieldValue('maxWeightPerPiece')}
                minDensity={form.getFieldValue('minDensity')}
                maxDensity={form.getFieldValue('maxDensity')}
                requiredUnitSystem={unitSystem}
                convertToImperial={convertToImperial}
                convertToMetric={convertToMetric}
                dataSource={dataSource}
                setDataSource={setDataSource}
                setBoxUnitSystem={setBoxUnitSystem} // Callback to update boxUnitSystem
              />
            </Panel>
          </Collapse>
          <Space style={cardSectionStyle}>
            <Form.Item style={{ marginTop: 24 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Card>
    </div>
  );
};

export default DimensionsMasterForm;