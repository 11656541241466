import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Modal, Checkbox, Row, Col, message } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { formStyle, sectionStyle, cardSectionHeaderStyle, reducedMarginFormItem } from '../formStyles';
import axios from 'axios';
import { AvailabilityObj } from './helpers/VuelosDisponibles';

export const FlightList = ({ form, list, personalInfoFilled }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [availableFlights, setAvailableFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');

  // Fetch flights from an API when modal opens
  useEffect(() => {
    if (isModalVisible) {
      fetchFlights();
    }
  }, [isModalVisible]);


  const formatDate = (dateTime) => {
    if (!dateTime) return 'N/A';
    const date = new Date(dateTime);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${month}/${day} ${hours}:${minutes}`;
  };

  const FlightNr = (reference) => {
    return reference.split('/')[0];
  }

  const fetchFlights = async () => {
    const values = form.getFieldsValue([
      'agent',
      'product',
      'originAirportCode',
      'destinationAirportCode',
      'weight',
      'departureOn'
    ]);

    setLoading(true);
    message.info('Fetch started ' + values.originAirportCode);
    try {

      if (values.originAirportCode === values.destinationAirportCode) {
        form.message.error('Origin y Dest Are The Same');
        return
      }
      // Replace with your actual API endpoint
      //const response = await axios.get('https://your-api-endpoint.com/flights');

      const response = await AvailabilityObj(
        values.agent,
        values.destinationAirportCode,
        values.originAirportCode,
        values.weight,
        values.departureOn,
        token,
        values.product);

      message.info('Fetch finished:', response);
      setAvailableFlights(response);//.data);
    } catch (error) {
      console.error('Error fetching flights:', error);
      setAvailableFlights([]);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Update the form with selected flights
    form.setFieldsValue({
      flights: selectedFlights.map(flight => ({
        id: flight.id,
        segments: (flight.segments || []),
        price: flight.price,
      })),
    });
    setIsModalVisible(false);
    console.log('Serialized Form Data:', form.getFieldsValue());
  };

  const handleCancel = () => {
    setSelectedFlights([]);
    setIsModalVisible(false);
  };

  const onFlightChange = (flight) => {
    setSelectedFlights(prev => {
      const exists = prev.some(f => f.id === flight.id);
      if (exists) {
        return prev.filter(f => f.id !== flight.id);
      }
      return [...prev, flight];
    });
  };

  return (
    <>
    {/*<Card title="Flights" 
      style={sectionStyle}
      headStyle={cardSectionHeaderStyle}
    >*/}
      <Form.List name="flights">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} gutter={4} style={{ marginBottom: 0 }}>
                {/*<Col span={6}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'id']}
                    rules={[{ required: true, message: 'Id is required!' }]}
                  >
                    <Input disabled placeholder="Id" />
                  </Form.Item>
                </Col>*/}
                <Col span={16}>
                  <Form.List name={[field.name, 'segments']}>
                    {(segmentFields, { add: addSegment, remove: removeSegment }) => (
                      <>
                        {segmentFields.map(segmentField => (
                          <Row key={segmentField.key} gutter={0} style={{ height: '1rem', marginBottom: 0 }}>
                            <Col span={4}>
                              <Form.Item
                                {...segmentField}
                                name={[segmentField.name, 'segmentId']}
                              //fieldKey={[segmentField.fieldKey, 'segmentId']}
                              //rules={[{ required: true, message: 'Segment ID is required!' }]}
                              >
                                {/* Display segmentId as text */}
                                <div style={{ width: '100%' }}>
                                  {form.getFieldValue(['flights', field.name, 'segments', segmentField.name, 'onload', 'code']) || 'N/A'}-
                                  {form.getFieldValue(['flights', field.name, 'segments', segmentField.name, 'offload', 'code']) || 'N/A'}
                                </div>
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item
                                {...segmentField}
                                name={[segmentField.name, 'segmentId']}
                              >
                                <div style={{ width: '100%' }}>
                                  {FlightNr(
                                  form.getFieldValue(['flights', field.name, 'segments', segmentField.name, 'transportMeans', 'reference'])) || 'N/A'}
                                </div>
                              </Form.Item>
                            </Col>

                            <Col span={6}>
                              <Form.Item
                                {...segmentField}
                                name={[segmentField.name, 'segmentId']}
                              >
                                <span>{formatDate(
                                  form.getFieldValue(['flights', field.name, 'segments', segmentField.name, 'transportMeans', 'scheduledDeparture'])
                                )}
                                </span>

                              </Form.Item>
                            </Col>

                            <Col span={6}>
                              <Form.Item
                                {...segmentField}
                                name={[segmentField.name, 'segmentId']}
                              >
                                <span>{formatDate(
                                  form.getFieldValue(['flights', field.name, 'segments', segmentField.name, 'transportMeans', 'scheduledArrival'])
                                )}
                                </span>

                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col sm={24} md={10}>
                      <Form.Item className="listitem"
                        {...field}
                        name={[field.name, 'pieces']}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input placeholder="Pieces" />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={14}>
                      <Form.Item className="listitem"
                        {...field}
                        name={[field.name, 'weight']}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input placeholder="Weight Lb" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={2} className='remove-icon-wrapper'>
                  <MinusCircleOutlined
                    onClick={() => remove(field.name)}
                    style={{ marginTop: 8 }}
                  />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed"
                onClick={showModal}
                block
                icon={<PlusOutlined />}
                disabled={!personalInfoFilled} // Disable if personal info is not filled
              >
                Add Flight
              </Button>
            </Form.Item>
          </>
        )
        }
      </Form.List >

      <Modal
        title="Select Flights"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        confirmLoading={loading}
      >
        {loading ? (
          <p>Loading flights...</p>
        ) : (
          availableFlights.map(flight => (
            <Checkbox
              key={flight?.segments[0].transportMeans?.id ?? ''}
              checked={selectedFlights.some(f => f.id === flight.id)}
              onChange={() => onFlightChange(flight)}
            >
              <Col>
              {/*`json:${flight?.segments[0].transportMeans?.id??''}`*/}
              {flight.segments.map(s => (
                <Row>
                {`${s.onload.code}-${s.offload.code} 
              ${s.transportMeans.reference} 
              ${s.transportMeans.scheduledDeparture}`}<br/>
              </Row>
              ))}
              </Col>
              {/*`json: ${serializeForm(flight)}`*/}
            </Checkbox>
          ))
        )}
      </Modal>
    {/*</Card >*/}
    </>
  );
};

export default FlightList;