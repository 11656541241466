import { texture } from "three/tsl";

// Style for Forms
export const formStyle = {
    maxWidth: '1200px', 
    height: '100%', 
    margin: '0 auto', 
    backgroundColor: '#eee', 
    padding: '1rem 0.2rem' 
};

// Style for Forms
export const cardStyle = {
    borderRadius: '8px',
    padding: '0px',
    marginBottom: '8px',
    boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f5f5f5'
};

// Style for rounded rectangles
export const sectionStyle = {
    borderRadius: '8px',
    padding: '0px',
    marginBottom: '8px',
    boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f5f5f5'
};

// Style for form sections inside cards
export const cardSectionStyle = {
    padding: '1rem',
    borderRadius: '0px',
};

export const cardSectionHeaderStyle={ 
    backgroundColor: '#e6e6ff', 
    fontWeight: 'bold',
    minHeight: '2rem',    
    textAlign: 'left',
    borderRadius: '0px',
    fontSize: '1rem',
};

// Style for card headers
export const cardHeaderStyle = {
    backgroundColor: '#000066', //'#d0d2e5', // Light blue background
    minHeight: '2rem',
    color: '#ffffff' // Ant Design's primary blue color for text
};

// Style for card body
export const cardBodyStyle = {
    margin: '0px',
    padding: '0px',
    //backgroundColor: '#d0d2e5', // Light grey background
    minHeight: '2rem',
    //display: 'none',
    //color: '#1890ff' // Ant Design's primary blue color for text
};

// Style for card body
export const cardBodyHidden = {
    //backgroundColor: '#d0d2e5', // Light grey background
    //minHeight: '2rem',
    display: 'none',
    //color: '#1890ff' // Ant Design's primary blue color for text
};

// Custom style to reduce spacing between form items
export const reducedMarginFormItem = {
    marginBottom: '8px', // Reduced from default 24px
    padding: '0px'
};